import React, { Component, useEffect, useState } from 'react';
import '../../App.css';
import { Box, Button, FormControl, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { Card, CardContent, Grid, Typography, Snackbar } from '@mui/material';
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import TextContainer from '../TextContainer';
import axios from 'axios';

class Zwischenstand extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: []
        }
    }

    /*state = {
        data: [
            { platz: 1, firstname: 'Annika', lastname: 'Kramer', Punkte: 10 },
            { platz: 1, firstname: 'Bruno', lastname: 'Merkel', Punkte: 10 },
            { platz: 1, firstname: 'Kurt', lastname: 'Werz', Punkte: 10 },
            { platz: 4, firstname: 'Dario', lastname: 'Micic', Punkte: 9 },
            { platz: 4, firstname: 'Jessica', lastname: 'Plünske', Punkte: 9 },
            { platz: 4, firstname: 'Franz', lastname: 'Stöferle', Punkte: 9 },
            { platz: 7, firstname: 'Elmar', lastname: 'Dehler', Punkte: 8 },
            { platz: 7, firstname: 'Gerhard', lastname: 'Bayer', Punkte: 8 },
            { platz: 7, firstname: 'Martin', lastname: 'Huber', Punkte: 8 },
            { platz: 7, firstname: 'Lena', lastname: 'Salamon', Punkte: 8 },
            { platz: 7, firstname: 'Dorothee', lastname: 'Färber', Punkte: 8 },
            { platz: 7, firstname: 'Niklas', lastname: 'Romer', Punkte: 8 },
            { platz: 13, firstname: 'Stefan', lastname: 'Braunger', Punkte: 7 },
            { platz: 13, firstname: 'Hartmut', lastname: 'Pohl', Punkte: 7 },
            { platz: 13, firstname: 'Lea', lastname: 'Stöferle', Punkte: 7 },
            { platz: 13, firstname: 'Andreas', lastname: 'Gapp', Punkte: 7 },
            { platz: 13, firstname: 'Uli', lastname: 'Heim', Punkte: 7 },
            { platz: 13, firstname: 'Markus', lastname: 'Beyerlin', Punkte: 7 },
            { platz: 13, firstname: 'Tobias', lastname: 'Wilhelm', Punkte: 7 },
            { platz: 13, firstname: 'Ralf', lastname: 'Scheck', Punkte: 7 },
            { platz: 13, firstname: 'Herrmann', lastname: 'Werz', Punkte: 7 }
        ]
    };*/

    componentDidMount() {
        //get request
        axios.get('https://svs-tippspiel.de/api/getGesamtpunktzahl.php').then(res => {

            this.setState({ data: res.data });
        });

    }

    render() {
        window.scrollTo(0, 0)
        return (
            <>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <h1>Zwischenstand</h1>
                </div>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <Table style={{ width: '80%', maxWidth: '600px' }}>
                        <TableHead>
                            <TableRow>
                                <TableCell> <strong>Platz</strong></TableCell>
                                <TableCell><strong>Vorname</strong></TableCell>
                                <TableCell><strong>Nachname</strong></TableCell>
                                <TableCell><strong>Punkte</strong></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.data.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell>{index + 1}.</TableCell>
                                    <TableCell>{row.firstname}</TableCell>
                                    <TableCell>{row.lastname}</TableCell>
                                    <TableCell>{row.Punkte}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div >
            </>
        );
    }
}

export default Zwischenstand;